<template lang="pug">
div.video_record.fill
  div#ask_title
    span.asked_by {{ askedByText }}
    br
    span {{ currentAskText }}
  div.select_input_device_container(v-show="showSelectDevices")
    div.select_input_device
      Multiselect(
        v-model="selectedVideoLabel",
        v-bind:options="inputVideoDeviceLabels",
        :searchable="false",
        :close-on-select="true",
        :show-labels="false"
        placeholder="Select video input"
      )
    div.select_input_device
      Multiselect(
        v-model="selectedAudioLabel",
        v-bind:options="inputAudioDeviceLabels",
        :searchable="false",
        :close-on-select="true",
        :show-labels="false"
        placeholder="Select audio input"
      )
  div.video_controls
    input#keyshortcut.hideaway(v-on:keyup="keySpaceToSubmit")
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
    div.wrapper(v-show="!loading")
      img.start(v-show="!isRecording" v-on:click="startRecording" ref="start" style="touch-action: manipulation" :src="recordStartButtonSrc" :title="recordButtonTitle")
      img.start(v-show="hasRecording && !isPlaying" v-on:click="playRecording" ref="play" style="touch-action: manipulation" :src="playButtonSrc")
      div.stop_recording_container(v-show="isRecording || (hasRecording && isPlaying)" v-on:click="stopRecording" ref="stop" style="touch-action: manipulation")
        div#countdown_container(v-show="showTimer")
          CountdownTimer(
            :startTime="recordingLimit"
            v-bind:run="runTimer"
            v-on:timerEnd="timerEnd"
          )
        img.stop(:src="stopButtonSrc" :title="stopButtonTitle")
      img.next(v-show="hasRecording" v-on:click="saveRecording" ref="save" style="touch-action: manipulation" :src="nextButtonSrc" :title="nextButtonTitle")
    img.settings(v-show="showSettings" v-on:click="showSelectDevices = !showSelectDevices" ref="settings" style="touch-action: manipulation" :src="settingsIconSrc" :title="settingsTitle")
    div.link_skip
      a(v-show="hasVideo"  style="touch-action: manipulation" v-on:click="nextAsk") Skip
  video#preview(autoplay
        playsinline
        muted
        )
  video#playback(autoplay
        playsinline
        loop
  )

</template>

<script>

import { ADD_VIDEO_MUTATION } from '../graphql/mutations'
import { GET_PROJECT_QUERY, GET_VIDEO_BY_ASK_QUERY } from '../graphql/queries'
import {_} from 'vue-underscore';
import Multiselect from 'vue-multiselect'
import CountdownTimer from '../components/CountdownTimer'
import config from '../appConfig'

export default {
  name: 'AddVideo',
  props: {
  },
  components: {
    Multiselect,
    CountdownTimer
  },
  computed: {
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    settingsIconSrc () {
      return process.env.BASE_URL + "video_settings_button.svg"
    },
    settingsTitle () {
      return "change video/audio device input"
    },
    recordButtonTitle () {
      if (this.hasRecording) { return "Re-Record" }
      return "Record"
    },
    stopButtonTitle () {
      if (this.hasRecording) { return "Pause playback" }
      return "Stop recording"
    },
    nextButtonTitle () {
      return "Save recording and go to next step"
    },
    nextButtonSrc () {
      return process.env.BASE_URL + "video_save_button.svg"
    },
    recordStartButtonSrc () {
      return process.env.BASE_URL + "record_start_button.svg"
    },
    playButtonSrc () {
      return process.env.BASE_URL + "play_button.svg"
    },
    stopButtonSrc () {
      if (this.isRecording) {
        return process.env.BASE_URL + "record_stop_button.svg"
      } else if (this.hasRecording) {
        const video = document.querySelector('video#playback')
        if (video.paused) {
          return process.env.BASE_URL + "playback_stop_button.svg"
        }

      }
      return process.env.BASE_URL + "playback_stop_button.svg"
    },
    showSettings () {
      return !config.isSafari
    },
    recordingLimit () {
      return config.recordLimitInSecs
    },
    currentProject () {
      return this.$store.state.currentProject
    },
    currentUser () {
      return this.$store.state.user.user
    },
    hasIntroVideo () {
      return this.currentProject && this.currentProject.introVideoUrl
    },
    inputVideoDevices () {
      return _.where(this.inputDevices, {kind: "videoinput"})
    },
    inputAudioDevices () {
      return _.where(this.inputDevices, {kind: "audioinput"})
    },
    inputVideoDeviceLabels () {
      return _.map(this.inputVideoDevices, function(inputDevice) {
        return inputDevice.label
      })
    },
    inputAudioDeviceLabels () {
      return _.map(this.inputAudioDevices, function(inputDevice) {
        return inputDevice.label == "" ? "internal" : inputDevice.label
      })
    },
    projectAsks () {
      return this.project.asks
    },
    askCount () {
      return this.projectAsks.length
    },
    currentAsk () {
      return this.projectAsks[this.currentAskIndex]
    },
    currentAskText () {
      if (!this.project) return ""
      return this.currentAsk.text
    },
    askedByText () {
      if (!this.project) return ""
      return this.project.user.firstName + " asks..."
    },
    hasVideo () {
      if (!this.video) return false
      return true
    },
  },
  watch: {
    selectedVideoLabel: function() {
      const selectedVideoDevice = _.findWhere(this.inputVideoDevices, {label: this.selectedVideoLabel})
      this.allStop()
      this.mediaDeviceConstraints.video.deviceId = {exact: selectedVideoDevice.deviceId}
      this.setupVideoPreview()
    },
    selectedAudioLabel: function() {
      const selectedAudioDevice = _.findWhere(this.inputAudioDevices, {label: this.selectedAudioLabel})
      this.allStop()
      this.mediaDeviceConstraints.audio.deviceId = {exact: selectedAudioDevice.deviceId}
      this.setupVideoPreview()
    },
    project: function () {
      this.$store.commit('setProject', this.project)
      this.currentAskId = this.project.asks[this.currentAskIndex].id
    },
    currentAskIndex: function () {
      this.currentAskId = this.project.asks[this.currentAskIndex].id
    }
  },
  data () {
    return {
      showSelectDevices: false,
      showTimer: false,
      runTimer: false,
      inputDevices: [],
      selectedVideoLabel: null,
      selectedAudioLabel: null,
      isRecording: false,
      isPlaying: false,
      hasRecording: false,
      recordedBlobs: [],
      mediaRecorder: null,
      loading: false,
      currentAskIndex: 0,
      project: null,
      currentAskId: null,
      mediaDeviceConstraints: config.mediaDeviceConstraints,
      video: null,
    }
  },
  apollo: {
    project () {
      return {
        query: GET_PROJECT_QUERY,
        variables () {
          return {
           id: this.currentProject.id
          }
        },
        skip () {
          return !this.currentProject?.id
        },
        fetchPolicy: 'cache-and-network',
      }
    },
    video () {
      return {
        query: GET_VIDEO_BY_ASK_QUERY,
        variables () {
          return {
            askId: this.currentAskId,
          }
        },
        // Disable the query
        skip () {
          return !this.currentAskId
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
  methods: {
    keySpaceToSubmit: function(e) {
      // console.log(e.keyCode)
      if (e.keyCode === 32) {
        if (this.isRecording) {
          this.stopRecording()
        } else {
          this.startRecording()
        }
      }
    },
    timerEnd: function () {
      this.stopRecording()
    },
    getDevices: function() {
      navigator.mediaDevices.enumerateDevices().then((inputDevices) =>{
        this.inputDevices = inputDevices
      }).catch((error) => {
        console.log(error)
      })
    },
    addVideo: async function(file, askId) {
      this.loading = true
      this.$apollo.mutate({
        mutation: ADD_VIDEO_MUTATION,
        variables: {
          input: {
            projectId: this.currentProject.id,
            askId: askId,
            file: file
          }
        },
        context: {
          hasUpload: true
        },
      }).then((data) => {
        console.log(data)
        // console.log(data.data.addVideo)
        this.loading = false
        this.nextAsk()
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    nextAsk: function () {
      if (this.currentAskIndex >= this.askCount - 1) {
          this.next()
        } else {
          this.resetRecording()
          this.currentAskIndex = this.currentAskIndex + 1
        }
    },
    saveRecording: function() {
      const askId = this.currentAsk.id
      const fileName = "video_by_"+this.currentUser.id+"_for_project_"+this.$store.state.currentProject.id+"_for_ask_"+askId+".mp4"
      const file = new File(this.recordedBlobs, fileName, {type: this.recordedBlobs[0].type})
      this.addVideo(file, askId)
    },
    handleDataAvailable: function(event) {
      // console.log('handleDataAvailable', event)
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data)
      }
    },
    loadRecording: function () {
      const superBuffer = new Blob(this.recordedBlobs, {type: 'video/webm'});
      var video = document.querySelector("video#playback")
      video.srcObject = null
      video.src = window.URL.createObjectURL(superBuffer)
      video.controls = false
      video.pause()
    },
    playRecording: function() {
      // console.log('----------playRecording')
      var video = document.querySelector("video#playback")
      var playPromise = video.play()
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Automatic playback started!
          this.isPlaying = true
        }).catch(function(error) {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
          console.error(error)
        });
      }
    },
    stopRecording: function() {
      document.querySelector('#keyshortcut').focus()

      this.runTimer = false
      this.showTimer = false

      if (this.hasRecording) {
        var video = document.querySelector("video#playback")
        video.pause()
        this.isPlaying = false
      } else {
        if (this.mediaRecorder != null) this.mediaRecorder.stop()
        this.hasRecording = true
        this.isRecording = false
      }
    },
    resetRecording: function() {
      this.recordedBlobs = []
      this.hasRecording = false
      var video = document.querySelector("video#playback")
      if (video.src) {
        window.URL.revokeObjectURL(video.src)
        video.removeAttribute('src');
        video.load()
      }
    },
    startRecording: function() {
      this.resetRecording()
      document.querySelector('#keyshortcut').focus()

      let options = config.recordingOptions
      try {
        this.mediaRecorder = new MediaRecorder(window.stream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        return;
      }

      // console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
      this.mediaRecorder.onstop = (event) => {
        // console.log('Recorder stopped: ', event);
        // console.log('Recorded Blobs: ', this.recordedBlobs);
        if (event.type == "stop") {
          this.loadRecording()
        }
      };
      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start()
      this.isRecording = true
      // console.log('MediaRecorder started', this.mediaRecorder);

      this.runTimer = true
      this.showTimer = true
    },
    setupVideoPreview: async function() {

      try {
        const stream = await navigator.mediaDevices.getUserMedia(this.mediaDeviceConstraints)
        var video = document.querySelector("video#preview")
        video.muted = true // otherwise audio feedback will occur
        video.srcObject = stream
        window.stream = stream  // make stream available to console
        // console.log("getUserMedia() got stream:", stream)
      } catch (e) {
        console.error('navigator.mediaDevices.getUserMedia error:', e)
        if (/Permission denied/i.test(e)) {
          this.$toast.error("You have blocked this page from accessing your camera and microphone.  In the address bar, look for the icon that shows site permissions, and click to approve camera and microphone access and reload the page.",{timeout: false})
        }
      }

    },
    stopStreaming: function() {
      // untested alternate way
      // function stop() {
      //   if (window.stream) {
      //     window.stream.getTracks().forEach(function(track) {
      //       track.stop()
      //     })
      //   }
      // }

      var video = document.querySelector("video#preview")
      const stream = video.srcObject

      if (stream == null) return

      const tracks = stream.getTracks()

      tracks.forEach(function(track) {
        track.stop();
      });

      video.srcObject = null;
    },
    openFullscreen: function() {
      var elem = document.querySelector(".video_record")

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    allStop: function() {
      this.stopStreaming()
      this.stopRecording()
      this.resetRecording()
    },
    next: function() {
      this.$router.push({name:"Done"})
    },
  },
  beforeRouteLeave(to, from, next) {
    this.allStop()
    next()
  },
  beforeUnmount: function() {
    this.allStop()
  },
  mounted: function () {
    window.vue = this
    this.setupVideoPreview()
    this.getDevices()

    document.querySelector('#keyshortcut').focus()
  }
}
</script>

<style scoped>
.video_record {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
}

.overlay {
  display: block;
  position: absolute;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.asked_by {
  font-size: 24px;
  font-weight: normal;
}
#ask_title {
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  position: relative;
  margin-top: 20%;
  z-index: 1000;
  text-shadow: 4px 4px 6px rgba(0,0,0,0.30);

}

.fill {
  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  z-index: 500;
  top: 45%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 0 2px 40px rgba(0,0,0,0.45);
}

#countdown_container {
  position: absolute;
  z-index: 800;
  top: 0px;
  left: 9px;
  width: 100px;
  height: 100px;
}

.stop_recording_container {
  display: inline-block;
  position: relative;
}

.link_skip {
  position: absolute;
  bottom: -25px;
  width: 100%;
  text-align: center;
}

.select_input_device_container {
  position: absolute;
  z-index: 800;
  bottom: 30%;
  width: 260px;
  border: none;
  text-align: left;
}

.select_input_device {
  border: 2px solid rgba(255,255,255,0.8);
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0 2px 10px rgba(0,0,0,0.50);
  box-shadow: 0 2px 10px rgba(0,0,0,0.20);
  margin-bottom: 10px;
}

.settings {
  cursor: pointer;
  display: block;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 30%;
  left: 2%;
}

</style>
